import React from "react"
import { FaBell } from 'react-icons/fa'
import { Link } from "gatsby"

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <section className="section has-background-light" name="sign-up" id="sign-up">
      <div className="container">
        <h3 className="title is-family-secondary">Sign up to observe a case below.</h3>
        <div className="content">
          <p>
            Schedule is downloaded from <a href="https://jpwebsite.harriscountytx.gov/WebDockets/prompt.jsp" target="_blank" rel="noopener noreferrer">Harris County JP Online Docket</a>.
            Note that each court will have different case hearing procedures.
          </p>
          <div className="notification is-warning is-light">
            <p>
              <span className="tag-wrapper">
                <span className="tag has-addons is-rounded is-danger">Friendly Reminder <FaBell/></span>
              </span>
              You must have attended training to observe a case!
            </p>
            <p className="call-to-action">
              Please <Link to="/training" className="button is-warning is-rounded is-small" activeClassName="is-focused">
                register here
              </Link> and attend a training before signing up to observe below.
            </p>
          </div>
          <p>You'll receive an email with observation links and reminders after you sign up!</p>
          <div className="lds-dual-ring">
            <iframe
              className="airtable-embed airtable-dynamic-height"
              title="Sign Up for a Docket"
              src="https://airtable.com/embed/shrvvAPR4ynPOxfXk?backgroundColor=transparent"
              frameBorder="0"
              onMouseWheel=""
              width="100%"
              height="1117"></iframe>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
